import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Modal, ModalBody } from 'reactstrap'
import { PublicApi } from '../api'
import { useLocation } from 'react-router-dom'
import LanguageContext from '../providers/language-provider'
import style from './about-us-page.module.scss'
const AboutUsPage = () => {
  const [ article, setArticle ] = useState({})
  const [ teamMembers, setTeamMembers ] = useState([])
  const { selectedLanguage } = useContext(LanguageContext)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ selectedTeamMember, setSelectedTeamMember ] = useState({})
  const [ selectedTeamMemberSummary, setSelectedTeamMemberSummary ] = useState({})
  const location = useLocation()
  const locationName = location.pathname.split('/')

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  useEffect(() => {
    PublicApi.indexArticles({ location: locationName[ 1 ] })
      .then((response) => {
        const listArticles = response.data.data
        setArticle(listArticles[0])
      })
      .catch(() => {
        alert('Something is Wrong')
      })

    PublicApi.indexTeamMember()
      .then((response) => {
        const listTeamMembers = response.data.data
        setTeamMembers(listTeamMembers)
      })
      .catch(() => {
        alert('Something is Wrong')
      })
  }, [])

  useEffect(() => {
    const summary = selectedTeamMember?.summaries?.find((sum) => sum.language.code === selectedLanguage)
    setSelectedTeamMemberSummary(summary)
  },[selectedLanguage, selectedTeamMember])

  const detail = (article) => article?.details?.find((det) => det.language.code === selectedLanguage)
  const handleClickMember = (teamMember) => {
    setSelectedTeamMember(teamMember)
    toggleModal()
  }
  return(
    <>
      <section className='py-5'>
        <Container>
          <h2 className='text-primary'>{ detail(article)?.title }</h2>
          <div className='d-lg-flex'>
            <div className='pe-5'>
              <span dangerouslySetInnerHTML={{ __html: detail(article)?.content }} />
            </div>
          </div>
        </Container>
      </section>
      <section className='py-5'>
        <Container>
          <h2 className='text-primary'><strong>{ 'Team' }</strong>{ ' Member' }</h2>
          <div
            className='d-flex'
            style={{
              overflow  : 'hidden',
              overflowX : 'scroll'
            }}
          >
            {
              teamMembers.map((teamMember) => (
                <div
                  key={ `team-member-${teamMember?.name}` }
                  className='p-4 rounded shadow m-4 text-center d-flex flex-column justify-content-center align-items-center'
                  style={{
                    minWidth: '247px'
                  }}
                >
                  <img
                    className={ `rounded-circle ${style.teamMemberPhoto}` }
                    src={ teamMember?.media?.mediaUrl }
                    alt="Team Member"
                    width={ 123 }
                    height={ 123 }
                    style={{
                      objectFit      : 'cover',
                      objectPosition : 'center center'
                    }}
                    onClick={ () => handleClickMember(teamMember) }
                  />
                  <div className='small mt-3'>
                    { teamMember?.jobDesk }
                  </div>
                  <div className='fw-bold h4 mt-auto'>
                    { teamMember?.name }
                  </div>
                </div>
              ))
            }
          </div>
        </Container>
      </section>

      <Modal
        isOpen={ isModalOpen }
        toggle={ toggleModal }
        centered
      >
        <ModalBody className='d-flex flex-column' >
          <Button
            className='ms-auto rounded align-items-end'
            color='danger'
            onClick={ toggleModal }
          >
            { 'X' }
          </Button>
          <div className='d-flex mb-4'>
            <img
              className={ 'rounded-circle border border-warning me-3' }
              src={ selectedTeamMember?.media?.mediaUrl }
              alt="Team Member"
              width={ 123 }
              height={ 123 }
              style={{
                objectFit      : 'cover',
                objectPosition : 'center center'
              }}
            />
            <div>
              <div className='small text-nowrap mt-3'>
                { selectedTeamMember?.jobDesk }
              </div>
              <div className='fw-bold h4 mt-2'>
                { selectedTeamMember?.name }
              </div>
            </div>
          </div>
          <span dangerouslySetInnerHTML={{ __html: selectedTeamMemberSummary?.summary }} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default AboutUsPage